.arrow {
  animation: slide 1s ease-in-out infinite;
}

@keyframes slide {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(0, 10px);
  }
}
