@import "../partials";

.landing-page {
  position: relative;
  .clouds {
    position: absolute;
    height: 100vh;
    width: 1500px;
    animation: move 30s infinite linear;
  }
  @keyframes move {
    0% {
      transform: translateX(0px);
    }
    50% {
      transform: translateX(-700px);
    }
    100% {
      transform: translateX(0px);
    }
  }

  .gold-cloud {
    width: 50%;
    position: absolute;
    top: calc(50%);
    left: calc(50%);
    transform: translate(-50%, -50%);
    animation: glow 1s infinite alternate;
    @include mq(mobile) {
      width: 100%;
    }
  }

  @keyframes glow {
    from {
      filter: drop-shadow(0 0 0 gold);
    }
    to {
      filter: drop-shadow(0 0 7px gold);
    }
  }

  @import "Arrow";
  .arrow {
    position: absolute;
    bottom: 75px;
    left: calc(50% - 12px);
  }

  height: 100vh;
  @import "SocialLinks";
  @include mq(mobile) {
    .SocialLinks {
      display: none;
    }
  }
}
