@import-normalize;
@import url("https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@500&family=Michroma&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bai+Jamjuree&display=swap");

*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

#root {
  font-size: 20px;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

@import "partials";
@import "components";

canvas {
  position: absolute;
  top: 0;
  opacity: 0;
  &.loaded {
    opacity: 1;
    transition: all 1s;
  }
}

.flex {
  display: flex;
}

.logo {
  position: absolute;
  z-index: 1;
  width: 112px;
  height: 79px;
  left: 64px;
  top: 40px;
  @include mq(mobile) {
    left: 2.5rem;
    top: 1.6rem;
  }

  /* Head */
  font-family: Chakra Petch;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 35px;

  /* or 109% */
  display: flex;
  align-items: center;

  &.gold {
    color: $gold;
  }
  &.blue {
    color: $blue;
  }
}

.menu-text {
  position: fixed;
  z-index: 2;
  width: 87px;
  height: 75px;
  right: 56px;
  top: 38px;

  /* Button */
  font-family: Michroma;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 40px;
  display: flex;
  align-items: center;
  text-align: right;

  color: $blue;
  @include mq(mobile) {
    display: none;
  }
}

.menu-icon {
  display: none;
  position: fixed;
  right: 2.5rem;
  top: 50px;
  z-index: 2;
  @include mq(mobile) {
    display: block;
  }
}
