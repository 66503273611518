.info-page {
  height: 100%;
  min-height: 100vh;
  .flex {
    padding: 200px 12% 0;
    @include mq(mobile) {
      flex-direction: column-reverse;
      align-items: center;
      padding: 150px 5% 0;
    }
    .content {
      width: 70%;
      font-family: Bai Jamjuree;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 30px;
      color: $white;
      @include mq(mobile) {
        width: 85%;
      }
      > div {
        &:last-child {
          margin-top: 50px;
        }
      }
    }
    .info-photo {
      margin: 0 0 0 50px;
      width: 300px;
      height: 450px;
      @include mq(mobile) {
        margin: 0 0 60px 0;
      }
    }
  }

  @import "SocialLinks";
  .SocialLinks {
    padding: 4rem 3rem 2rem;
  }
}
