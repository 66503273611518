.photo-page {
  margin-top: 5rem;
  display: grid;
  grid-template-columns: 0.5fr 400px 400px 0.5fr;
  grid-template-rows: 1fr 30px 1fr;
  grid-template-areas: "grid-left grid-j1 grid-j2 grid-right" "grid-left grid-br grid-br grid-right" "grid-left grid-j3 grid-j4 grid-right";

  .grid-br {
    grid-area: grid-br;
  }

  .grid-j1 {
    grid-area: grid-j1;
  }

  .grid-j2 {
    grid-area: grid-j2;
  }

  .grid-j3 {
    grid-area: grid-j3;
  }

  .grid-j4 {
    grid-area: grid-j4;
  }
  > img {
    width: 400px;
    height: 600px;
  }
  @include mq(mobile) {
    grid-template-columns: 0.5fr 240px 0.5fr;
    grid-template-rows: 360px 360px 360px 360px;
    grid-template-areas: "grid-left grid-j1 grid-right" "grid-left grid-j2 grid-right" "grid-left grid-j3 grid-right" "grid-left grid-j4 grid-right";
    > img {
      width: 240px;
      height: 360px;
    }
  }
}
