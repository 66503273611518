.Notice {
  font-family: Michroma;
  font-size: 15px;
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 9rem;
  padding: 0 1rem;
  z-index: 2;
  > a {
    color: $gold;
  }
}
