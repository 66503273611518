.album-page {
  display: flex;
  padding-top: 120px;
  @include mq(mobile) {
    flex-direction: column;
  }
  .album-desc {
    width: 50%;
    color: $gold;
    font-style: italic;
    padding-left: 20%;
    .title {
      height: 123px;
      font-family: Chakra Petch;
      font-weight: bold;
      font-size: 38px;
    }
    .quote {
      display: flex;
      width: 250px;
      @include mq(mobile) {
        width: 100%;
      }
      .rect {
        width: 4px;
        height: 93px;
        background: $gold;
        @include mq(mobile) {
          display: none;
        }
      }
      .text {
        margin-left: 20px;
        font-family: Bai Jamjuree;
        font-weight: normal;
        font-size: 22px;
        line-height: 30px;
        @include mq(mobile) {
          margin: 0;
        }
      }
    }
    @include mq(mobile) {
      padding: 0;
      text-align: center;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .TrackList {
    margin-top: 90px;
    font-family: Michroma;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 52px;
    z-index: 1;
    letter-spacing: 0.05em;
    a {
      color: $blue;
    }
    @include mq(mobile) {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 0.8rem;
    }
  }
}
