.media-page {
  height: 100%;
  min-height: 100vh;
  padding-bottom: 3rem;
  .grid {
    padding: 200px 10% 0;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-auto-rows: 40px;
    .media-card {
      background: white;
      border-radius: 1.5rem;
      overflow: hidden;
      margin-bottom: 10px;
      &:hover {
        transition: box-shadow 0.3s ease;
        box-shadow: 0 0 0.4rem 0.4rem $gold;
      }
      img {
        width: 100%;
      }
      .title {
        padding: 1rem;
        color: $grey;
      }
    }
  }
}
