.Footer {
  position: relative;
  height: 500px;
  z-index: 1;
  .goldCloud {
    position: absolute;
    left: calc(50% - 100px);
    bottom: 20px;
    width: 200px;
    cursor: url("../flag.cur"), auto;
  }
}
