.SocialLinks {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-top: calc(100vh - 100px);
  padding: calc(100vh - 100px) 150px 0 150px;
  z-index: 1;
  @include mq(mobile) {
    flex-wrap: wrap;
    align-items: center;
    > div {
      text-align: center;
      height: 50px;
      width: 120px;
    }
  }
  div {
    font-family: Bai Jamjuree;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;

    a {
      color: #b6aaff;
    }
  }
}
