.fade-enter,
.fade-appear {
  opacity: 0;
  transition: opacity 2s ease;
}

.fade-enter-active,
.fade-appear-active {
  opacity: 1;
}

.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: all 700ms;
}
