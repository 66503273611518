.white-blue {
  background: rgb(67, 58, 254);
  background: linear-gradient(
    0deg,
    rgba(67, 58, 254, 1) 0%,
    rgba(108, 101, 250, 1) 27%,
    rgba(255, 255, 255, 1) 100%
  );
}

.blue-white {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(108, 101, 250, 1) 73%,
    rgba(67, 58, 254, 1) 100%
  );
}
