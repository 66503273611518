.mv-page {
  padding: 0 150px;
  @include mq(mobile) {
    padding: 0 1rem;
  }
  .player-wrapper {
    position: relative;
    padding-top: 56.25%;
    .react-player {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
