.menu-page {
  .sky {
    position: absolute;
    width: 100%;
    height: 100vh;
    opacity: 0.6;
    z-index: -1;
  }
  .nav {
    display: flex;
    flex-direction: column;
    padding-top: 100px;
    font-family: Michroma;
    > a {
      color: $blue;
    }
  }
  .SocialLinks {
    font-family: Bai Jamjuree;
    > div > a {
      color: $grey;
    }
  }
  .nav,
  .SocialLinks {
    text-align: center;
    font-size: 28px;
    > div,
    a {
      height: 75px;
    }
  }
}
